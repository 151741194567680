<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <vb-headers-heading-2
              :data="{
                title: 'Editer le contact',
                description:'',
              }"
            />
          </div>
          <div class="card-body"><contact-form /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VbHeadersHeading2 from '@/@vb/widgets/Headers/Heading2'
import contactForm from '@/components/widgets/Forms/contactForm'

export default {
  name: 'VbContactITES',
  components: {
    VbHeadersHeading2,
    contactForm,
  },
}
</script>
