<template>
  <a-form :model="formState" label-align="left" layout="vertical" @submit="onSubmit">
    <div class="row">
      <div class="col-lg-6">
        <a-form-item label="Adress">
          <a-input v-model:value="formState.adress" placeholder="Adress" />
        </a-form-item>
      </div>             
      <div class="col-lg-6">
        <a-form-item label="Téléphone">
          <a-input v-model:value="formState.phone" placeholder="Téléphone" />
        </a-form-item>         
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <a-form-item label="E-mail">
          <a-input v-model:value="formState.email" placeholder="E-mail" />
        </a-form-item>
      </div>             
      <div class="col-lg-6">
        <a-form-item label="Facebook">
          <a-input v-model:value="formState.facebook" placeholder="Facebook" />
        </a-form-item>         
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <a-form-item label="Twitter">
          <a-input v-model:value="formState.twitter" placeholder="Twitter" />
        </a-form-item>
      </div>             
      <div class="col-lg-6">
        <a-form-item label="Youtube">
          <a-input v-model:value="formState.youtube" placeholder="Youtube" />
        </a-form-item>         
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <a-form-item label="LinkedIn">
          <a-input v-model:value="formState.linkedin" placeholder="LinkedIn" />
        </a-form-item>
      </div>             
      <div class="col-lg-6">
        <a-form-item label="Instagram">
          <a-input v-model:value="formState.instagram" placeholder="Instagram" />
        </a-form-item>         
      </div>
    </div> 
    <button type="submit" class="btn btn-primary px-5">Mise à jour  </button>         
  </a-form>
</template>
<script>
/* eslint-disable */
import {toRaw } from 'vue'
import { message } from 'ant-design-vue';

import ApiClient from "@/services/axios"

export default {
  created(){
    ApiClient.post('/cordonnees/filter',{
      query:{
      }
    })
    .then((res)=>this.formState=res.data[0])
    .catch(()=>message.warning('Impossible de trouver le contact existant'))
  },
  data(){
    return {
      formState:{
        adress: "",
        phone: "",
        email: "",
        facebook: "",
        twitter: "",
        youtube: "",
        linkedin: "",
        instagram: "",
      }
    }
  },
   methods: {
     onSubmit(){
      const data = toRaw(this.formState)
      ApiClient.patch("/cordonnees/"+this.formState._id,{data})
      .then((res)=>{
        message.success('Le contact a été mis à jour')
      })
    .catch((e)=>{
        message.warning("Impossible de ajouter l'utilisateur")
      }) 
     },       
    },
}
</script>
